import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
let webhostname = '';
let webApiHostName = '';

if (window.location.hostname === 'app.investifyd.com') {
  webhostname = 'https://app.investifyd.com';
  webApiHostName = 'https://app.investifyd.com/api';
} else if (window.location.hostname === 'stage.app.investifyd.com') {
  webhostname = 'https://stage.app.investifyd.com';
  webApiHostName = 'https://stage.app.investifyd.com/api';
  // ".."
} else {
  webhostname = 'http://localhost:3000';
  webApiHostName = 'https://stage.app.investifyd.com/api';
  //webApiHostName = 'http://localhost:4000';
}
export const apihost = webApiHostName;
export const webhost = webhostname;

export const jsonHeader = {
  Accept: 'application/json',
  'content-type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'x-app-type': 'WEBAPP',
};

export const tokenHeader = {
  'x-access-token': localStorage.getItem('authToken'),
};

export const checkStatus = response => {
  const successCodes = [200, 201, 204,202];

  if (
    response.status >= 200 &&
    response.status < 300 &&
    successCodes.includes(response.status)
  ) {
    return response;
  } else {
    throw response;
  }
};

// Import all bank images from ../../assets/img/bankImg/ folder
// and create a all images object
export const importAllImages = r => {
  let images = {};
  r.keys().map(item => {
    images[item.replace('./', '')] = r(item);
    return null;
  });
  return images;
};
export const parseJSON = response => {
  return response.json();
};

const redirectToLogin = () => {
  localStorage.clear();
  return (window.location.href = webhostname + '/login');
};

export const getErrorMessage = response => {
  let errorMsg = '';
  let success = false;
  try {
    return parseJSON(response).then(error => {
      if (error.data) {
        errorMsg = error.data;
      } else if (_.get(error, 'error', false)) {
        errorMsg = error.error;
        success = error.success;
      } else if (_.get(error, 'message', false)) {
        errorMsg = error.message;
        success = error.success;
      } else if (_.get(error, 'errorMessage', false)) {
        errorMsg = error.errorMessage;
        success = error.success;
      } else if (_.get(error, 'error_message', false)) {
        errorMsg = error.error_message;
        success = error.success;
      } else if (_.get(error, 'error.message', false)) {
        errorMsg = error.error.message;
        success = error.success;
      } else if (_.get(error, 'error.name.error_message', false)) {
        errorMsg = error.error.name.error_message;
        success = error.success;
      } else {
        errorMsg =
          'There was error in processing your request, Please try again.';
      }
      return errorMsg;
    });
  } catch (error) {
    errorMsg = 'There was error in processing your request, Please try again.';
    return Promise.resolve(errorMsg);
  }
};

export const handleApiError = async error => {
  let errorMessage =
    'There was error in processing your request, Please try again.';
  let errorStatus = error.status;
  try {
    errorMessage = await getErrorMessage(error);
    if (errorStatus === 401 && errorMessage === 'jwt expired') {
      // redirect to login
      errorMessage = 'Token is expired.. redirect you to login';
      redirectToLogin();
    } else if (
      error === 'jwt expired' ||
      error === 'Failed to authenticate token.'
    ) {
      errorMessage = 'Token is expired.. redirect you to login';
    } else if (errorStatus === 500) {
      //errorMessage = 'Something went wrong. Please try again later.';
      errorMessage = errorMessage;
    } else if (error === 'Error from BSE : Invalid Encrypted Password') {
      errorMessage = 'Something went wrong. Please try again later.';
    } else {
      return errorMessage;
    }
    // else if (errorStatus === 401) {
    //   // simply show message
    //   console.log('Unauthorized operation being performed.');
    //   errorMessage = 'Something went wrong. Please try again later.';
    // }
    return errorMessage;
  } catch (err) {
    console.log(err);
    return errorMessage;
  }
};
export const multipartjsonHeader = {
  mimeType: 'multipart/form-data',
  'x-app-type': 'WEBAPP',
};
// Global map to keep track of ongoing requests by their endpoint
const abortControllers = {};

export const fetchAPI = async (endpoint, method, body) => {
  const url = webApiHostName + endpoint;

  // If there's an ongoing request for this endpoint, abort it
  if (abortControllers[endpoint]) {
    abortControllers[endpoint].abort();
  }

  // Create a new controller for the current request
  const controller = new AbortController();
  abortControllers[endpoint] = controller;

  try {
    const headers = new Headers({
      ...jsonHeader,
      'x-access-token': localStorage.getItem('authToken'),
    });
    //signal: controller.signal
    const options = { headers, method };
    if (method === 'POST') options.body = body;

    let response = await fetch(url, options);

    localStorage.setItem('APIStatusCode', response.status);
    await checkStatus(response);

    let apiData = await parseJSON(response);

    // Clear the controller once the request is finished
    delete abortControllers[endpoint];

    return apiData;
  } catch (err) {
    // Clear the controller in case of an error
    delete abortControllers[endpoint];

    if (err.name === 'AbortError') {
      console.log(`Fetch request to ${endpoint} was aborted.`);
      return;
    }

    let errObj = await handleApiError(err);
    throw errObj;
  }
};

// Similarly, update fetchMediaAPI
export const fetchMediaAPI = async (endpoint, method, body) => {
  const url = webApiHostName + endpoint;

  if (abortControllers[endpoint]) {
    abortControllers[endpoint].abort();
  }

  const controller = new AbortController();
  abortControllers[endpoint] = controller;

  try {
    const headers = new Headers({
      ...multipartjsonHeader,
      'x-access-token': localStorage.getItem('authToken'),
    });

    const options = { headers, method, signal: controller.signal };
    if (method === 'POST') options.body = body;

    let response = await fetch(url, options);

    await checkStatus(response);

    let apiData = await parseJSON(response);

    delete abortControllers[endpoint];

    return apiData;
  } catch (err) {
    delete abortControllers[endpoint];

    if (err.name === 'AbortError') {
      console.log(`Fetch request to ${endpoint} was aborted.`);
      return;
    }

    let errObj = await handleApiError(err);
    throw errObj;
  }
};

export const fetchLoginAPI = async (endpoint, method, body) => {
  if (abortControllers[endpoint]) {
    abortControllers[endpoint].abort();
  }

  const controller = new AbortController();
  abortControllers[endpoint] = controller;

  try {
    const url = webApiHostName + endpoint;
    const headers = new Headers({ ...jsonHeader });

    const options = { headers, method };
    if (method === 'POST') options.body = body;

    let response = await fetch(url, options);
    await checkStatus(response);
    let apiData = await parseJSON(response);
    return apiData;
  } catch (err) {
    let errObj = await handleApiError(err);
    throw errObj;
  }
};
