import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { layoutTypes } from './constants/layout';
// Import Routes all
import { authProtectedRoutes, publicRoutes } from './routes';

// Import all middleware
import Authmiddleware from './routes/route';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';

import './assets/scss/theme.scss';
import 'react-rangeslider/lib/index.css';
import GoogleTagManager from 'analytics/GoogleTagManager';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { SocketContext, socket } from 'context/socket';
import socketEvents from 'utils/socketEvents';
import { useEffect } from 'react';
import { useState } from 'react';
import AuthLayout from 'components/AuthLayout';
import NonAuthLayout from 'components/NonAuthLayout';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from 'components/NotFound/NotFound';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the CSS for skeleton loader

const getLayout = layoutType => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};
const getAuthLayout = layoutType => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = AuthLayout;
      break;
    default:
      break;
  }
  return Layout;
};
const App = () => {
  let [isSocket, setIsSocket] = useState(false);
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }));
  useEffect(() => {
    // if (localStorage.getItem('investorId')) {
    // if(isSocket){
    socketEvents(socket, changeSocketState);
    // }
    return () => {
      socket.disconnect();
      socket.off('connect');
      socket.off('disconnect');
    };
  }, [socket]);
  const changeSocketState = val => {
    setIsSocket(val);
  };
  useEffect(() => {
    //initGA();
    //logPageView();
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
          console.log('Complete Cache Cleared', name);
        });
      });
    }
  }, []);

  const Layout = getLayout(layoutType);
  const AuthLayout1 = getAuthLayout(layoutType);
  const isAuthenticated = !!localStorage.getItem('auth');
  return (
    <React.Fragment>
      <SocketContext.Provider value={socket}>
        <Helmet>
          <meta name="viewport" content="width=device-width" />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-WT73Y74Q4R"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-WT73Y74Q4R');
          `}
          </script>
          <script>
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NPRWF6V');
          `}
          </script>
        </Helmet>
        <GoogleTagManager />
        <ToastContainer />
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                route.path === '/register' ? (
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                ) : (
                  <AuthLayout1>{route.component}</AuthLayout1>
                )
              }
              key={idx}
              exact={true}
              isAuthenticated={isAuthenticated}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                isAuthenticated ? (
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
              key={idx}
              exact={true}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SocketContext.Provider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
