import React from 'react';
import { Navigate } from 'react-router-dom';

const Authmiddleware = ({ children, isAuthenticated, location }) => {
  
  if (isAuthenticated) {
    return (
      <Navigate
        to={{ pathname: '/dashboard', state: { from: location } }}
        replace
      />
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default Authmiddleware;
